import React, { useContext, useMemo } from 'react'
import { useHistory } from 'react-router-dom'
import { useUnit } from 'effector-react'

import Sidebar from '../../PracticeSidebar/Sidebar'
import PracticeModeHeader from '../PracticeModeHeader/PracticeModeHeader'
import usePracticeProblemsStore from '../../models/practiceProblems'
import { useTextToSpeechStore } from '../../../TextToSpeech/model'
import { Context as UserSettingsContext } from '../../../../context/userSettingsContext'
import { translateCurrentTask } from '../../../Problem/helpers'
import { fullscreenModeOff, removeKatexPartFromTextToSpeech } from '../../../../helpers'
import '../../../DrawBoard/styles.scss'
import { usePracticeLoadingModel } from '../../models/practiceLoading'
import SidebarLoader from './SidebarLoader/SidebarLoader'
import ToolBarPractice from '../ToolBarPractice/ToolBarPractice'
import { DescriptionExercise, ExerciseDescription } from '../../../exerciseDescription/ExerciseDescription'
import { skillInfoModel } from '../../../practiceModeSkills/model/skillInfoModel'
import usePracticeStore from '../../models/practice'
import { WebStartDraggingProps } from '../../../exerciseDescription/types'

type PracticeModeSidebarProps = {
  onDragStart: (event: React.DragEvent, src: string, options?: WebStartDraggingProps) => void
}

const PracticeModeSidebar = ({ onDragStart }: PracticeModeSidebarProps) => {
  const history = useHistory()
  const { problemsList, currentProblemIndex, currentProblem } = usePracticeProblemsStore()
  const openedSkillInfo = useUnit(skillInfoModel.$openedSkillInfo)

  const { openedAbilityInfo } = usePracticeStore()
  const { isTranslated, setIsTranslated } = useTextToSpeechStore((state) => state)
  const { state: userSettingsState } = useContext(UserSettingsContext)
  const isLoadingAbilities = usePracticeLoadingModel((state) => state.loadingAbility)

  const textToSpeechContent = useMemo(() => {
    return isTranslated
      ? currentProblem?.translatedTextToSpeech
      : removeKatexPartFromTextToSpeech(currentProblem?.richDescription)
  }, [currentProblem?.richDescription, currentProblem?.translatedTextToSpeech, isTranslated])

  const problemsWithSubmittedAnswersCount = problemsList.reduce(
    (accumulator, problemObj) => (problemObj.isSubmittedInCurrentSession ? ++accumulator : accumulator),
    0
  )

  const problemProgress = (problemsWithSubmittedAnswersCount / problemsList.length) * 100

  const handleTranslateExercise = async () => {
    await translateCurrentTask({
      isTranslated,
      setIsTranslated,
      problem: currentProblem,
      subProblems: null,
      translationLanguage: userSettingsState.translationLanguage,
    })
  }

  const onExitButtonClick = () => {
    history.goBack()
    fullscreenModeOff()
  }

  return (
    <Sidebar>
      <PracticeModeHeader
        problemNumber={currentProblemIndex + 1}
        onClick={onExitButtonClick}
        title={
          userSettingsState.practiceModeTreeId ? openedSkillInfo?.nameTranslations || '' : openedAbilityInfo.primaryName
        }
        problemProgress={problemProgress}
      />
      {isLoadingAbilities ? (
        <SidebarLoader />
      ) : (
        <>
          <ToolBarPractice textToSpeechContent={textToSpeechContent} />
          {currentProblem && (
            <ExerciseDescription
              exercise={currentProblem as unknown as DescriptionExercise}
              onTranslateButtonPress={handleTranslateExercise}
              hideProblemSelector
              mergeLinebreaks
              onDragStart={onDragStart}
            />
          )}
        </>
      )}
    </Sidebar>
  )
}

export default PracticeModeSidebar
