import React from 'react'
import styles from './styles.module.scss'
import clsx from 'clsx'
import { MagmaChatIcon } from '../../../../ui/icons/MagmaChat/MagmaChatIcon'
import Markdown from '../../../../components/katexMarkdown/Markdown'
import { DotsLoader } from '../../../../components/DotsLoader'

type ChatMessageProps = {
  content?: string
  className?: string
  containerClassName?: string
  isOwnMessage: boolean
  isIconHidden?: boolean
  iconSize?: number
  isLoading?: boolean
}

export const ChatMessage = ({
  content = '',
  containerClassName,
  className,
  isOwnMessage,
  isIconHidden = false,
  iconSize = 40,
  isLoading = false,
}: ChatMessageProps) => {
  const hasIcon = !isOwnMessage && !isIconHidden

  return (
    <div className={clsx(styles.ChatMessageContainer, { [styles.OwnMessage]: isOwnMessage }, containerClassName)}>
      {hasIcon && (
        <div>
          <MagmaChatIcon size={iconSize} />
        </div>
      )}

      <div
        className={clsx(styles.ChatMessage, {
          [styles.OwnMessage]: isOwnMessage,
          className,
        })}
      >
        {isLoading ? <DotsLoader /> : <Markdown>{content}</Markdown>}
      </div>
    </div>
  )
}
