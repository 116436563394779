import { vars_ls, mistrans, replacements, getOpsLs } from './validation.constants'

// Define ops_ls as a let variable at the top level
let ops_ls: string[] = []

//Clean string from redundant symbols and translate common myscript mistranscriptions
function cleanString(string: string, clean_answer: string, problem: string) {

  let new_string = string.replace(/n/g, ' ').replace(/dfrac\{([^}]+)\}\{([^}]+)\}/g, '$1/$2')

  //Replace common mistranscriptions in string
  if (!(problem.includes('{,}') || clean_answer.includes('.'))) {
    new_string = new_string.replace(/\.(\d)/g, '*$1')
  }
  new_string = new_string.replace(/,/g, '.')

  for (const [key, value] of Object.entries(mistrans)) {
    if (new_string.includes(value) && !problem.includes(value)) {
      new_string = new_string.replace(new RegExp(value, 'g'), key)
    }
  }

  //When common mistranscriptions are replaced, remove everything from new_string except units, operators, and numbers
  const cleaned_string = new_string
    .split('')
    .filter((char, i) => {
      return (
        char.match(/[\d\s.]/) ||
        ops_ls.includes(char) ||
        (vars_ls.includes(char) && !new_string[i - 1]?.match(/[a-zA-Z]/) && !new_string[i + 1]?.match(/[a-zA-Z]/))
      )
    })
    .join('')

  new_string = cleaned_string.split(/\s+/).join(' ')
  if (new_string.endsWith('.')) {
    new_string = new_string.slice(0, -1)
  }

  return new_string

}

//Clean answer
function cleanAnswer(answer: string) {

  let new_answer = answer
    .replace(/,/g, '.')
    .replace(/[,\[\]"]/g, '')
    .replace(/([a-zA-Z])\^.*$/, '$1')

  new_answer = Array.from(new_answer)
    .filter((char) => /\d/.test(char) || vars_ls.includes(char) || ['.', '-', '+', '*', '/', '='].includes(char))
    .join('')
    .trim()

    return new_answer
}

//Transalte segments of problem description from latex code to text
function cleanProblem(problem: string) {

  let new_problem = problem
  for (const [pattern, replacement] of Object.entries(replacements)) {
    new_problem = new_problem.replace(new RegExp(pattern, 'g'), replacement)
  }

  new_problem = new_problem.trim()

  return new_problem

}

function getProblemRatio(sol_ls: string[], problem_ls: string[], answer_ls: string[]) {

  if (!sol_ls.length) {
    return 0.0
  }

  const filteredProblemLs = problem_ls.filter((item: string) => item !== '=')
  let filteredSolLs = sol_ls.filter((item: string) => item !== '=')
  //If '*' is present in problem '+' is allowed in solution without impacting the ratio of relevant operators
  if (filteredProblemLs.includes('*') && !filteredProblemLs.includes('+')) {
    filteredSolLs = filteredSolLs.filter((item: string) => item !== '+')
  }
  // Remove '/' if filteredSolLs only includes '/' and one other operator to allow students to do vertical addition/subtraction
  if (filteredSolLs.length === 2 && filteredSolLs.includes('/')) {
    const otherOperator = filteredSolLs.find((op: string) => op !== '/')
    if (otherOperator) {
      filteredSolLs = [otherOperator]
    }
  }

  // Don't include answer elements when computing ratio of solution relevant to problem
  const sol_for_ratio = filteredSolLs.slice()
  answer_ls.forEach((element: string) => {
    const index = sol_for_ratio.indexOf(element)
    if (index > -1) {
      sol_for_ratio.splice(index, 1)
    }
  })

  const problem_set = new Set(filteredProblemLs)
  const overlap_count = sol_for_ratio.reduce((count: number, item: string) => count + (problem_set.has(item) ? 1 : 0), 0)

  return sol_for_ratio.length > 0 ? overlap_count / sol_for_ratio.length : 0
  
}

function getRatioFormat(string_ls: string[]) {

  const total_elements = string_ls.length
  let valid_elements = 0
  let expecting_digit_or_var = true
  let prev_element: string | null = null

  string_ls.forEach((element: string) => {
    if (expecting_digit_or_var) {
      if (
        (element.replace('.', '').match(/^\d+$/) || vars_ls.includes(element)) &&
        (prev_element === null || prev_element !== element)
      ) {
        valid_elements += 1
        expecting_digit_or_var = false
      }
    } else {
      if (ops_ls.includes(element)) {
        valid_elements += 1
        expecting_digit_or_var = true
      }
    }
    prev_element = element
  })

  return total_elements > 0 ? valid_elements / total_elements : 0
}

function getMath(string: string) {

  // Find digits and single digits in solution
  const digits = string.match(/\d+(?:\.\d+)?/g) || []
  const single_digits = string.replace(/\D/g, '').split('')

  // Find all operators in the string
  const ops = string.split('').filter((char: string) => ops_ls.includes(char))

  // Create a list of all digits, operators, and variables in order
  function escapeRegExp(string: string) {
    return string.replace(/[.*+?^${}()|[\]\\]/g, '\\$&')
  }
  const all_elements =
    string.match(
      new RegExp(
        `\\d+(?:\\.\\d+)?|[${escapeRegExp(
          ops_ls.join('').replace(/[-/\\^$*+?.()|[\]{}]/g, '\\$&') + vars_ls.join('')
        )}]`,
        'g'
      )
    ) || []

  return [digits, single_digits, ops, all_elements]
}

function checkForAnswer(string: string, answer: string) {

  // Convert both string and answer to lowercase for case-insensitive comparison
  let string_lower = string.toLowerCase()
  const answer_lower = answer.toLowerCase()

  // Check if answer appears anywhere in the string
  const answer_in_string =
    string_lower.split(/[\s+\-*/]/).includes(answer_lower) ||
    string_lower.split(/[\s+\-*/]/).includes(answer_lower.replace('-', '')) || // Check for negative answer with missing '-'
    string_lower.split(/[\s+\-*/]/).includes(answer_lower.replace('.', '')) // Check for decimal answer with missing '.'

  // Check if answer appears after an equals sign
  const parts = string_lower.split(/=|\//)
  const answer_after_equals = parts.length > 1 && parts.slice(1).some((part) => part.includes(answer_lower))

  // Check if the last digit in the string matches at least 2/3 of the answer to counteract transcription errors
  const last_chars_in_string = string_lower.slice(-answer_lower.length)
  const match_length = Math.floor((answer_lower.length * 2) / 3)
  if (last_chars_in_string.slice(-match_length) === answer_lower.slice(-match_length) && answer_lower.length > 2) {
    return true
  }

  return answer_after_equals || answer_in_string
  
}

// string = Solution, problem = Problem description, answer = Answer
export function featuresSolution(stringRaw: string, answerRaw: string, problemRaw: string) {

  const features = {
    digits: 0,
    singleDigits: 0,
    ops: 0,
    digitsProblem: 0,
    opsProblem: 0,
    ratioProblem: 0,
    ratioDigits: 0,
    ratioSingleDigits: 0,
    ratioOps: 0,
    problemExpected: 0,
    ratioFormat: 0,
    ans: 0,
  }

  //Trim/translate solution string, answer and problem description
  const problem = cleanProblem(problemRaw)
  //Define list of operators depending on the problem description
  ops_ls = getOpsLs(problem)
  const answer = cleanAnswer(answerRaw)
  const string = cleanString(stringRaw, answer, problem)
  // Get digits, single digits, operators from solution string
  const [digits, single_digits, ops, all_math] = getMath(string)
  // Assign the amount of digits, single digits and operators as features of the solution string
  features.digits = digits.length
  features.singleDigits = single_digits.length
  features.ops = ops.length
  // Get ratio of solution written in correct format (digit, operator, digit, operator, etc)
  features.ratioFormat = parseFloat(getRatioFormat(all_math).toFixed(1))

  // Get digits and operators in answer
  const [digits_answer, single_digits_answer, ops_answer, all_math_answer] = getMath(answer)
  // Check if answer is present in solution
  features.ans = checkForAnswer(string, answer) ? 1 : 0

  // Get ratio of digits and operators present in problem and solution
  const [digits_problem, single_digits_problem, ops_problem] = getMath(problem)
  features.digitsProblem = digits_problem.length
  features.opsProblem = ops_problem.length
  if (digits_problem.length > 0) {
    features.problemExpected = 1
    features.ratioDigits = parseFloat(getProblemRatio(digits, digits_problem, digits_answer).toFixed(1))
    features.ratioSingleDigits = parseFloat(getProblemRatio(single_digits, single_digits_problem, single_digits_answer).toFixed(1))
  }
  if (ops_problem.length > 0) {
    features.ratioOps = parseFloat(getProblemRatio(ops, ops_problem, ops_answer).toFixed(1))
    features.ratioProblem = parseFloat(((features.ratioOps + features.ratioDigits) / 2).toFixed(1))
  } else {
    features.ratioProblem = features.ratioDigits
  }

  return features
}
