import { transformLatexToText } from '../../helpers/myScript/transformLatexToText'
import { featuresSolution } from './drawingValidation.helpers'

function validateTextExpression(mathExp: string, userAnswer: string, problemDescription: string) {

  // Adjustment to translation of fractions and exponentials
  mathExp = mathExp.replace(/\\dfrac\{(?<numerator>.*?)}\{(?<denominator>.*?)\}/g, '$<numerator>/$<denominator>').replace(/(?<base>.*?)\^\{(?<power>.*?)\}/gm, '$<base>^$<power>')
  
  const mathExpressionText = transformLatexToText(mathExp, { multiple: '*' })
  const { digits, ops, problemExpected, digitsProblem, opsProblem, ratioDigits, ratioOps, ratioSingleDigits, ans, ratioFormat } = featuresSolution(mathExpressionText, userAnswer, problemDescription)

  let ratioMath = 0
  if (ops > 0) {
    ratioMath = digits / ops
  }

  if (
    digits >= 2 &&
    ratioMath >= 0.75 &&
    ratioMath <= 4 &&
    ratioFormat >= 0.3 &&
    ((opsProblem > 0 && ratioOps > 0.7) || opsProblem === 0)
  ) {
    if (ops >= 2) {
      if (problemExpected === 1) {
        if (ans === 1) {
          if (digits >= 3) {
            if (ratioDigits > 0.3 && (ratioSingleDigits >= 0.6 || (digits >= 10 && ratioSingleDigits >= 0.25))) {
              return 1
            }
          }
        } else if (ratioDigits >= 0.5 && ratioSingleDigits >= 0.75 && digits <= digitsProblem * 2.5) {
          return 1
        }
      } else if (ans === 1 && ratioFormat >= 0.5 && digits >= 3) {
        return 1
      }
    } else if (ratioDigits >= 0.7) {
      if (ans === 1) {
        return 1
      } else if (ratioSingleDigits >= 0.75) {
        return 1
      }
    }
  }

  return 0
}

type ValidateLatexAnswerProps = {
  mathExp: string
  userAnswer: string | string[]
  problemDescription: string
}

export function validateLatexAnswer({ mathExp, userAnswer, problemDescription }: ValidateLatexAnswerProps) {
  const answer = Array.isArray(userAnswer) ? userAnswer[0] : userAnswer
  return !!validateTextExpression(mathExp, answer, problemDescription)
}
