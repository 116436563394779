import type { UserSettings } from '../../lib/types'

const HALMSTAD_MUNICIPALITY_ID = '6002f3e669d0d5104a057445'
const MIN_GRADE_HALMSTAD = 2
const MIN_GRADE_DEFAULT = 4

export const canUserAccessChat = (userSettings: UserSettings, classGrade?: number) => {
  if (!userSettings.chat) return false

  const grade = (userSettings.grade ?? classGrade) || 0
  const isHalmstadMunicipality = userSettings.municipality._id === HALMSTAD_MUNICIPALITY_ID

  if (isHalmstadMunicipality) {
    return grade >= MIN_GRADE_HALMSTAD
  }

  if (userSettings.grade === null || userSettings.grade === undefined || userSettings.grade === -1) {
    return classGrade ? classGrade >= MIN_GRADE_DEFAULT : false
  }

  return userSettings.grade >= MIN_GRADE_DEFAULT
}
