import { makeApiCall } from '../apiCallHelper'
import { USER_DATA } from '../../config/localStorageKeys'
import { api } from '../../api'
import { UserType } from '../../api/types.user'

export const getUserId = () => {
  return JSON.parse(localStorage.getItem(USER_DATA))._id
}

export const getUserType = () => {
  return JSON.parse(localStorage.getItem(USER_DATA))?.type
}

export const getUsername = () => {
  return `${JSON.parse(localStorage.getItem(USER_DATA))?.username}`
}

export const getUserGrade = () => {
  return getUserType() === UserType.TEACHER ? null : JSON.parse(localStorage.getItem(USER_DATA))?.grade
}

export const getUserFullProfileInfo = () => {
  const userData = JSON.parse(localStorage.getItem(USER_DATA))
  const { firstName, lastName, username } = userData
  const school = userData?.school?.name

  return { firstName, lastName, username, school }
}

export const getStudentSettings = async () => {
  return makeApiCall(async () => {
    const response = await api.me({ fetchCountry: 1 })
    return {
      ...response?.setting,
      grade: response?.grade,
      municipality: response?.municipality,
    }
  })
}

export const changeUserSettings = async ({ appLocale, translationLanguage, textToSpeech, textSize, canvasType }) => {
  return makeApiCall(() => {
    const body = {}
    appLocale && (body.appLocale = appLocale)
    translationLanguage !== undefined && (body.translationLanguage = translationLanguage)
    textToSpeech !== undefined && (body.textToSpeech = textToSpeech)
    textSize && (body.textSize = textSize)
    canvasType && (body.canvasType = canvasType)

    return api.updateUserSettings(body)
  })
}
