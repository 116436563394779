import React, { useContext, useEffect, useState } from 'react'
import './styles.scss'
import InfiniteScroll from 'react-infinite-scroll-component'
import { useTranslation } from 'react-i18next'
import { useUnit } from 'effector-react'

import { exerciseUpdated, refetchAssignments, statisticsUpdated, studentUpdated } from './socketListeners'
import SocketContext from '../../context/socketContext'
import ThreeDotsLoader from '../../ui/Loaders/ThreeDotsLoader'
import ExerciseItem from '../../features/ExerciseItem'
import { $hasMoreAssignments, $assignments } from '../../features/assignmentsPagination/model/assignments'
import { EXERCISES_LIST_TEST_ID } from '../../config/dataTestIDs'
import { fetchAssignmentsPageFx } from '../../features/assignmentsPagination/model/request'
import { SocketEvents } from '../../constants/socketEventsConstants'
import { Typography } from '@magmamath/ui'

const Exercises = () => {
  const { t, i18n } = useTranslation()
  const { socket } = useContext(SocketContext)
  const assignments = useUnit($assignments)
  const hasMoreAssignments = useUnit($hasMoreAssignments)
  const [isLoading, setIsLoading] = useState(false)

  useEffect(() => {
    setIsLoading(true)
    refetchAssignments().finally(() => setIsLoading(false))
  }, [i18n.language])

  useEffect(() => {
    socket.on(SocketEvents.EXERCISE_DELETED, refetchAssignments)
    socket.on(SocketEvents.EXERCISE_ADDED, refetchAssignments)
    socket.on(SocketEvents.EXERCISE_PUBLISHED, refetchAssignments)
    socket.on(SocketEvents.EXERCISE_ARCHIVED, refetchAssignments)
    socket.on(SocketEvents.EXERCISE_UPDATED, exerciseUpdated)
    socket.on(SocketEvents.STATISTICS_UPDATED, statisticsUpdated)
    socket.on(SocketEvents.STUDENT_CHEATING_UPDATED, studentUpdated)

    return () => {
      socket.off(SocketEvents.EXERCISE_DELETED, refetchAssignments)
      socket.off(SocketEvents.EXERCISE_ADDED, refetchAssignments)
      socket.off(SocketEvents.EXERCISE_PUBLISHED, refetchAssignments)
      socket.off(SocketEvents.EXERCISE_ARCHIVED, refetchAssignments)
      socket.off(SocketEvents.EXERCISE_UPDATED, exerciseUpdated)
      socket.off(SocketEvents.STATISTICS_UPDATED, statisticsUpdated)
      socket.off(SocketEvents.STUDENT_CHEATING_UPDATED, studentUpdated)
    }
  })

  if (isLoading) {
    return <ThreeDotsLoader />
  }

  if (!assignments.length) {
    return (
      <div className='exercises-container-empty' data-testid={EXERCISES_LIST_TEST_ID}>
        <Typography variant='h3'>{t('noAvailableExercisesText')}</Typography>
      </div>
    )
  }

  return (
    <div id='scrollableDiv' className='scroll-container'>
      <InfiniteScroll
        dataLength={assignments.length}
        next={fetchAssignmentsPageFx}
        hasMore={hasMoreAssignments}
        loader={<ThreeDotsLoader />}
        scrollableTarget='scrollableDiv'
      >
        <div className='exercises-container' data-testid={EXERCISES_LIST_TEST_ID}>
          {assignments.map((exercise) => (
            <ExerciseItem key={exercise._id} exercise={exercise} />
          ))}
        </div>
      </InfiniteScroll>
    </div>
  )
}

export default Exercises
