export enum UserType {
  TEACHER = 1,
  STUDENT = 2,
  ADMIN = 3,
}

export enum CanvasType {
  DRAWING = 1,
  SPREADSHEET = 2,
}

export type UserSettings = {
  appLanguage: string
  canvasType: CanvasType
  chat: boolean
  createdAt: string
  practiceMode: boolean
  practiceModeLockedGrades: string[]
  textToSpeech: boolean
  updatedAt: string
}

export type MeResponse = {
  acceptedAgreementAt: string
  autologinToken: string | null
  bookSeries: any[]
  completed: boolean
  createdAt: string
  deleted: boolean
  entityStatus: number
  firstName: string
  fullName: string
  grade: number
  gradeUpdated: string
  isAdmin: boolean
  lastName: string
  permissions: any[]
  phone: {
    attempt: number
    code: string
    number: string
    verify: boolean
  }
  profile: {
    _id: string
    createdAt: string
    profileImage: string
    updatedAt: string
  }
  roles: any[]
  school: {
    address: {
      city: string
    }
    code: number
    name: string
    organizationNumber: number
    type: number
    updatedAt: string
    _id: string
  }
  setting: UserSettings
  showStudentsMap: boolean
  source: number
  tokens: string[]
  type: UserType
  updatedAt: string
  username: string
  municipality: {
    _id: string
    name: string
    code: number
    bookSeries: string[]
    isPrivate: boolean
    source: number
    roles: string
    forceOnboarding: boolean
  }
  region: {
    _id: string
    bookSeries: string[]
    municipalities: string[]
    name: string
    createdAt: Date
    updatedAt: Date
    __v: 0
  }
  country: {
    _id: string
    municipalities: string[]
    name: string
    locale: string
    __v: number
    createdAt: Date
    updatedAt: Date
    icon: string
    bookSeries: string[]
    order: number
    dividedBasedOn: number
    regions: string[]
  }
  _id: string
}

export type UpdateUserSettingsProps = Partial<{
  appLocale: string
  translationLanguage: string
  textToSpeech: boolean
  textSize: number
  canvasType: string
}>
