import React, { useCallback, useContext, useEffect, useRef, useState } from 'react'
import { useHistory } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import { BoardActionsModule, BoardController, Magma } from '@magmamath/react-native-draw-board'

import styles from './styles.module.scss'
import PracticeModeSidebar from '../../features/PracticeMode/PracticeModeExercise/PracticeModeSidebar'
import PracticeModeDrawBoard from '../../features/PracticeMode/PracticeModeExercise/PracticeModeDrawBoard/PracticeModeDrawBoard'
import PracticeHandwritingAnswer from '../../features/PracticeMode/PracticeModeExercise/ProblemAnswers/PracticeHandwritingAnswer/PracticeHandwritingAnswer'
import EndExerciseModal from '../../features/PracticeMode/PracticeModeExercise/EndExerciseModal/EndExerciseModal'
import usePracticeProblemsStore from '../../features/PracticeMode/models/practiceProblems'
import { cleanAnswerSpaces } from '../../helpers/exercises/answerProblemHelper'
import { getDrawnImage } from '../../features/Problem/helpers'
import { isAnswerCorrect } from '../../features/Problem/problem.answer.helpers'
import { sendDrawingHistory, sendDrawnImage } from '../../features/assignments/requests'
import { useDrawBoardModel } from '../../features/DrawBoard/model'
import { sendAnswer } from '../../features/PracticeMode/requests'
import { currentProblemAnswerModel, ProblemAnswerStatus } from '../../features/Problem/models/answer'
import { answerAreaMyScriptModel } from '../../features/MyScript/model'
import { practiceLoadingModel, usePracticeLoadingModel } from '../../features/PracticeMode/models/practiceLoading'
import PracticeChoiceAnswer from '../../features/PracticeMode/PracticeModeExercise/ProblemAnswers/PracticeChoiceAnswer/PracticeChoiceAnswer'
import PracticeTheory from '../../features/PracticeMode/PracticeTheory/PracticeTheory'
import useTimeMeasure from '../../hooks/useTimeMeasure'
import { AnswerType } from '../../features/Problem/types.answer'
import { usePracticeTheory } from '../../features/PracticeMode/models/practiceTheory'
import { currentExerciseMode, ExerciseMode } from '../../features/Problem/models/exerciseMode'
import usePracticeStore from '../../features/PracticeMode/models/practice'
import { practicePageSessionActions } from '../../features/PracticeMode/sessionStorage'
import { useLoadAbilityCallback } from '../../features/PracticeMode/hooks/useLoadAbilityCallback'
import useLoadTheoryCallback from '../../features/PracticeMode/hooks/useLoadTheoryCallback'
import { useTextToSpeechStore } from '../../features/TextToSpeech/model'
import { drawBoardStatisticModule } from '../../features/DrawBoard/model.modules'
import { useAssignmentCredentials } from '../../features/assignment/hooks/useAssignmentCredentials'
import { skillInfoModel } from '../../features/practiceModeSkills/model/skillInfoModel'
import { fetchSkillsTreeStatisticsFx } from '../../features/practiceModeSkills/model/requests'
import { useUnit } from 'effector-react'
import { getMyScriptImage } from '../../features/MyScript/helpers'
import { Ability, PracticeSkill } from '../../features/PracticeMode/types'
import { Context as UserSettingsContext } from '../../context/userSettingsContext'
import { useSaveSessionOnUnload } from '../../features/PracticeMode/hooks/useSaveSessionOnUnload'
import PracticeSkillsExitModal from '../../features/PracticeMode/PracticeModeExercise/EndExerciseModal/PracticeSkillsExitModal'
import { sideBarModel } from '../../features/Problem/Sidebar/model'
import { practiceSkillsModel } from '../../features/practiceModeSkills/model/skillsTreeModel'
import { createSkillAttemptFx } from '../../features/practiceModeSkills/model/sendSkillAttempt'
import { getSkillTheoryFx } from '../../features/practiceModeSkills/model/skillTheory'

const PracticeExercise = () => {
  const { i18n } = useTranslation()
  const history = useHistory()
  const drawBoard = useRef<BoardController | null>(null)
  const {
    _id,
    problemsList,
    currentProblem,
    setIsSubmitted,
    currentProblemIndex,
    setCurrentProblemIndex,
    setEndProblems,
    endProblems,
    setRightAnswer,
  } = usePracticeProblemsStore()
  const drawBoardMode = useDrawBoardModel(({ mode }) => mode)
  const { setIsTranslated } = useTextToSpeechStore((setIsTranslated) => setIsTranslated)
  const credits = useAssignmentCredentials()
  const answerStatus = useUnit(currentProblemAnswerModel.$status)
  const isLoadingAbilities = usePracticeLoadingModel((state) => state.loadingAbility)
  const isSavingSkillAttempt = useUnit(createSkillAttemptFx.pending)
  const isPending = isLoadingAbilities || isSavingSkillAttempt
  const { theory, isLoading } = usePracticeTheory()
  const { state: userSettings } = useContext(UserSettingsContext)
  const { practiceModeTreeId } = userSettings
  const [drawBoardActions] = useState(() => new BoardActionsModule())
  drawBoardActions.setUp(drawBoard.current?.model || null)

  const {
    openedAbilityInfo,
    nextAbilityInfo,
    setNextAbilityInfo,
    statistics: abilitiesStatistics,
    setStatistics: setAbilitiesStatistics,
  } = usePracticeStore()
  const openedSkillInfo = useUnit(skillInfoModel.$openedSkillInfo)
  const nextSkillInfo = useUnit(skillInfoModel.$nextSkillInfo)
  const statistics = useUnit(practiceSkillsModel.$statistics)

  const { getTimeSpentInMilliseconds, resetStartTime } = useTimeMeasure([currentProblemIndex])

  const { loadAbility } = useLoadAbilityCallback()
  const { loadTheory: loadAbilityTheory } = useLoadTheoryCallback()

  useEffect(() => {
    currentExerciseMode.setExerciseMode(ExerciseMode.PRACTICE)
  }, [])

  useEffect(() => {
    currentProblemAnswerModel.reset()
    setIsTranslated(false)
    drawBoard.current?.model?.clear.board({ full: true })
    sideBarModel.setIsOpened(true)
  }, [credits.id, credits.problemId, currentProblemIndex])

  const getDrawingHistory = async (strokeCount: number) => {
    if (!strokeCount) {
      return
    }

    try {
      const rawHistory = drawBoard.current?.getHistory()
      const history = Magma.compression.server.compress(rawHistory || '')
      const drawingHistoryResponse = await sendDrawingHistory(history)
      return drawingHistoryResponse?._id
    } catch (error) {
      return
    }
  }

  const submitAnswer = useCallback(
    async (userAnswer: string[] | string) => {
      if (answerStatus) {
        if (currentProblemIndex + 1 >= problemsList.length) {
          setEndProblems(true)
          return
        }
        setCurrentProblemIndex(currentProblemIndex + 1)
        return
      }

      const timeToSolve = getTimeSpentInMilliseconds()
      resetStartTime()

      practiceLoadingModel.setSendingAnswer(true)
      setIsSubmitted()
      const answer = Array.isArray(userAnswer) ? [...userAnswer] : [cleanAnswerSpaces(userAnswer)]
      const currentTask = currentProblem ?? {}
      const correct = isAnswerCorrect(answer, currentTask, false, i18n.language, '', () => {})
      correct && setRightAnswer()
      const myscriptEditor = answerAreaMyScriptModel.currentEditor
      const isMyScriptEmpty = myscriptEditor?.context.empty
      const myscriptImage = myscriptEditor ? await getMyScriptImage(myscriptEditor) : null

      currentProblemAnswerModel.setStatus(!correct ? ProblemAnswerStatus.MISTAKE : ProblemAnswerStatus.SUCCESS)

      const strokeCount = drawBoardStatisticModule.counter.list.total

      const drawnImage = await getDrawnImage(drawBoard.current)
      const uploadedDrawnImageData = drawnImage ? await sendDrawnImage(drawnImage) : ''
      const drawingImageName = uploadedDrawnImageData?.image || ''

      const uploadedMyscriptImage = myscriptImage ? await sendDrawnImage(myscriptImage as any) : ''
      const handwritingImage = uploadedMyscriptImage?.image || ''

      const isMyScriptUsed = isMyScriptEmpty !== undefined && !isMyScriptEmpty
      const historyId = await getDrawingHistory(strokeCount)

      const problemObj = { ...currentProblem }
      delete problemObj.isSubmittedInCurrentSession
      delete problemObj.translatedTextToSpeech
      delete problemObj.translatedText

      try {
        await sendAnswer({
          body: {
            correct,
            answer,
            correctAnswer: currentProblem?.answer,
            drawingImageName,
            handwritingImage,
            type: drawBoardMode,
            strokeCount,
            recognitionUsed: isMyScriptUsed,
            problem: problemObj,
            timeToSolve,
            historyId,
            ...(practiceModeTreeId && openedSkillInfo?._id ? { skillId: openedSkillInfo?._id } : {}),
          },
          id: _id,
        })
      } catch (e) {
        practiceLoadingModel.setSendingAnswer(false)
      } finally {
        practiceLoadingModel.setSendingAnswer(false)
      }
    },
    [answerStatus, currentProblemIndex, problemsList, currentProblem, drawBoard, openedSkillInfo, practiceModeTreeId]
  )

  useSaveSessionOnUnload({
    statistics: practiceModeTreeId ? statistics : abilitiesStatistics,
    currentCard: practiceModeTreeId ? openedSkillInfo : openedAbilityInfo,
    nextCard: practiceModeTreeId ? nextSkillInfo : nextAbilityInfo,
  })

  if (!problemsList?.length && !isPending) {
    const [lastCardInfo, nextPromptedCardInfo, cardsStatistics] = [
      practicePageSessionActions.lastOpenedAbility.get(),
      practicePageSessionActions.nextPromptedAbility.get(),
      practicePageSessionActions.currentAbilitiesStatistics.get(),
    ]
    practicePageSessionActions.lastOpenedAbility.remove()
    practicePageSessionActions.nextPromptedAbility.remove()
    practicePageSessionActions.currentAbilitiesStatistics.remove()

    if (!lastCardInfo) {
      history.goBack()
      return null
    }

    if (practiceModeTreeId || 'nameTranslations' in lastCardInfo) {
      getSkillTheoryFx(lastCardInfo._id)

      createSkillAttemptFx(lastCardInfo as PracticeSkill)
      skillInfoModel.setOpenedSkillInfo(lastCardInfo as PracticeSkill)

      if (nextPromptedCardInfo) {
        skillInfoModel.setNextSkillInfo(nextPromptedCardInfo as PracticeSkill)
      }

      fetchSkillsTreeStatisticsFx()
    } else {
      loadAbilityTheory(lastCardInfo._id)
      loadAbility(lastCardInfo as Ability)

      if (nextPromptedCardInfo) {
        setNextAbilityInfo(nextPromptedCardInfo as Ability)
      }

      if (cardsStatistics) {
        setAbilitiesStatistics([cardsStatistics])
      }
    }
  }

  const resetIntersectionModuleActive = () => {
    drawBoard.current?.model.intersectionModule.$isActive.setValue(false)
  }

  const answerTypeMap = {
    [AnswerType.HANDWRITING]: (
      <PracticeHandwritingAnswer onSubmitAnswer={submitAnswer} onAnswerChangeStart={resetIntersectionModuleActive} />
    ),
    [AnswerType.SINGLE_CHOICE]: (
      <PracticeChoiceAnswer onSubmitAnswer={submitAnswer} choiceType={AnswerType.SINGLE_CHOICE} />
    ),
    [AnswerType.MULTIPLE_CHOICE]: (
      <PracticeChoiceAnswer onSubmitAnswer={submitAnswer} choiceType={AnswerType.MULTIPLE_CHOICE} />
    ),
    [AnswerType.ORDERED_CHOICE]: (
      <PracticeChoiceAnswer onSubmitAnswer={submitAnswer} choiceType={AnswerType.ORDERED_CHOICE} />
    ),
    default: (
      <PracticeHandwritingAnswer onSubmitAnswer={submitAnswer} onAnswerChangeStart={resetIntersectionModuleActive} />
    ),
  }

  return (
    <div className={styles.PracticeModePage} tabIndex={-1}>
      <PracticeModeSidebar onDragStart={drawBoardActions.images.webStartDragging} />
      <PracticeModeDrawBoard ref={drawBoard} />
      <div className={styles.ProblemAnswerPracticeContainer} tabIndex={-1}>
        {isPending
          ? null
          : answerTypeMap[currentProblem?.answerType as keyof typeof answerTypeMap] || answerTypeMap['default']}
      </div>
      {endProblems && (practiceModeTreeId ? <PracticeSkillsExitModal /> : <EndExerciseModal />)}
      {theory && !isLoading && <PracticeTheory />}
    </div>
  )
}

export default PracticeExercise
