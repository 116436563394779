import React from 'react'
import { createRoot } from 'react-dom/client'
import { BrowserRouter } from 'react-router-dom'
import App from './containers/App'
import serviceWorker from './serviceWorker'

import './index.css'
import 'animate.css'
import 'bootstrap/dist/css/bootstrap.min.css'
import '@magmamath/ui/dist/style.css'

import { AppProviders } from './providers/AppProviders'
import { initAnswerQueue } from './features/AnswersQueue/requests/upload'
import { initLegacyPolyfills } from './lib/legacy/initLegacyPolyfills'

initLegacyPolyfills()
initAnswerQueue()

const container = document.querySelector('#root')
const root = createRoot(container as HTMLDivElement)

root.render(
  <BrowserRouter>
    <AppProviders>
      <App />
    </AppProviders>
  </BrowserRouter>
)

serviceWorker()
