export const mistrans = { 4: 'y', 5: 's', 7: 'z' }
export const vars_ls = ['a', 'b', 'x', 'y', 'z']
//Translate problem description from latex code (This has to be done separately from string since the problem description is expressed differently than solution string)
export const replacements = {
    '\\\\frac\\{([^}]+)\\}\\{([^}]+)\\}': '$1/$2',
    '\\\\div': '/',
    '\\\\hspace\\{.*?\\}': ' ',
    '\\\\underline\\{[^}]*\\}': '?',
    '\\\\cdot': '*',
    '\\\\times': '*',
    '\\{,\\}': '.',
    ',': '.',
    '\\$': '',
    '\\\\Large': '',
    '\\\\quad': '',
    '\\\\': '',
    '\\{': '',
    '\\}': '',
    '\\s+': ' ',
    '(\\d)\\s+(\\d)': '$1$2',
    n: '',
  }

export function getOpsLs(problem: string) {
    var ops_ls = problem.includes('^') ? ['+', '-', '*', '/', '=', '^'] : ['+', '-', '*', '/', '=']
    return ops_ls
}